import React from "react";

function SvgTimeAndDate({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.462 13.162V5.85h13.163V3.656c0-.806-.656-1.462-1.463-1.462h-.73V0h-1.463v3.656h-.731V2.194h-5.12V0H3.657v3.656h-.731V2.194H1.463C.655 2.194 0 2.85 0 3.656v9.506c0 .807.656 1.463 1.462 1.463H5.85v-1.463H1.463zM12.939 18a5.068 5.068 0 01-5.063-5.063 5.068 5.068 0 015.063-5.062A5.068 5.068 0 0118 12.938 5.068 5.068 0 0112.937 18zm0-8.679a3.62 3.62 0 00-3.617 3.617 3.62 3.62 0 003.617 3.616 3.62 3.62 0 003.616-3.616 3.62 3.62 0 00-3.616-3.617zM15.75 13.5h-3.375v-3.375h1.688v2.025h1.687v1.35z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTimeAndDate;
